

import vid02 from '../../components/sliderv2/images/vid07.mp4'
import img1 from "../../components/sliderv2/images/img1.jpg"
import img2 from "../../components/sliderv2/images/img2.jpg"
import img4 from "../../components/sliderv2/images/img4.jpg"
import img5 from "../../components/sliderv2/images/img5.jpg"



export const sliderData = [
    {
      url: vid02,
      alt: "Dobrodošli u Nacrti Šibenik",
      title: "Dobrodošli u Nacrti Šibenik",
      description: "Pogledajete usluge koje nudimo.",
      isVideo: true,
    },
    {
      url: img1,
      alt: "Usluge Projektiranja",
      title: "Projektiranja",
      description: "Vršimo sve vrste usluga projektiranja!",
      isVideo: false,
    },
    {
      url: img2,
      alt: "Usluge Legaliziranja",
      title: "Legalizacije",
      description: "Vršimo usluge legalizacije objekta!",
      isVideo: false,
    },
    {
      url: img4,
      alt: "Usluge etaziranja",
      title: "Etažiranja",
      description: "Vršimo usluge etažiranja vašeg objekta!",
      isVideo: false,
    },
    {
      url: img5,
      alt: "Usluge nadzora",
      title: "Usluge nadzora",
      description: "Vršimo usluge nadzora objekta!",
      isVideo: false,
    },
  
  ];
