import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import "./Navbar.css";
import { HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import LogoImage from "../img/logo-test-svg.svg";

function Navbar() {
  const [click, setClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const menuRef = useRef(null);

  // Handle hamburger button click
  const handleClick = () => setClick(!click);

  // Check if we are on localhost
  const path = typeof window !== 'undefined' ? (window.location.hostname === "localhost" ? "" : "http://213.202.100.65/plesk-site-preview/www.nacrtisibenik.hr/http/213.202.100.65") : "";

  // Handle scroll event
  const handleScroll = () => {
    if (window.scrollY >= 3) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Handle click outside of mobile menu
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setClick(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
        <div className="nav-container">
          <NavLink
            exact
            to="/"
            className={`nav-logo ${scrolled ? "scrolled-color" : "default-color"}`}
          >
            <img
              src={LogoImage}
              className="nav-logo--img"
              alt="Radovi Sibenik"
            />
            <span
              className={`navbar-logo--text ${scrolled ? "scrolled-color" : "default-color"}`}
            >
              Šibenik d.o.o.
            </span>
          </NavLink>

          <ul ref={menuRef} className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName={`active ${scrolled ? "scrolled-color" : "default-color"}`}
                className={`nav-links ${scrolled ? "scrolled-color" : "default-color"}`}
                onClick={handleClick}
              >
                POCETNA
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName={`active ${scrolled ? "scrolled-color" : "default-color"}`}
                className={`nav-links ${scrolled ? "scrolled-color" : "default-color"}`}
                onClick={handleClick}
              >
                O NAMA
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                activeClassName={`active ${scrolled ? "scrolled-color" : "default-color"}`}
                className={`nav-links ${scrolled ? "scrolled-color" : "default-color"}`}
                onClick={handleClick}
              >
                USLUGE
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/references"
                activeClassName={`active ${scrolled ? "scrolled-color" : "default-color"}`}
                className={`nav-links ${scrolled ? "scrolled-color" : "default-color"}`}
                onClick={handleClick}
              >
                REFERENCE
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName={`active ${scrolled ? "scrolled-color" : "default-color"}`}
                className={`nav-links ${scrolled ? "scrolled-color" : "default-color"}`}
                onClick={handleClick}
              >
                KONTAKT
              </NavLink>
            </li>
            <ul className="social-media-list">
             
              <li className="nav-item-social-media">
                <a
                  href="https://www.instagram.com/na_crti/"
                  className="instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </ul>

          <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
