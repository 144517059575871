import React, { useState, useMemo } from "react";
import emailjs from "@emailjs/browser";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import "./EmailForm.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SpinnerDotted } from "spinners-react";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const EmailForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);

  // Coordinates for the map center and marker
  const center = useMemo(() => ({ lat: 43.7546392, lng: 15.8791169 }), []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const templateParams = {
      to_name: "Nacrti Šibenik d.o.o",
      to_email: "info@nacrtisibenik.hr",
      from_name: name,
      from_email: email,
      form_phone: phone,
      message: `
        Imate novi e-mail od ${name}:

        Ime: 
          ${name}

        Email: 
          ${email}

        Telefon: 
          ${phone}

       ----------------------------------------------
        Poruka: 

          ${message}

        Poslano od nacrtisibenik.hr
      `,
    };

    const ModalSwal = withReactContent(Swal);

    setSpinner(true);

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        setSpinner(false);
        ModalSwal.fire("E-Mail poslan uspješno!", "Poruka poslata!", "success");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      })
      .catch((error) => {
        setSpinner(false);
        ModalSwal.fire("Problem!", "E-mail nije poslan! Molimo vas pokušajte ponovo!", "error");
      });
  };

  return (
    <div className="container">
      <div className="contact-box">
        <div className="left">
          <MapContainer className="left" center={center} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={center} icon={defaultIcon}>
              <Popup>
                <h4>Nacrti-Šibenik d.o.o</h4>
                <p>Put Vida 19, 22000, Šibenik, Šibensko-kninska županija, Hrvatska</p>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
        <div className="right">
          <h2 className="contact-form--title">POŠALJITE NAM UPIT</h2>
          <form onSubmit={handleSubmit}>
            <input
              className="field"
              type="text"
              placeholder="Vaše ime i prezime"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              className="field"
              type="email"
              placeholder="Vaša E-Mail adresa"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              className="field"
              type="text"
              placeholder="Vaš broj telefona"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <textarea
              className="field"
              cols="30"
              rows="10"
              placeholder="Poruka"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>

            {spinner ? (
              <SpinnerDotted size={78} thickness={140} speed={114} color="rgba(57, 172, 167, 1)" />
            ) : (
              <button className="btn--send" type="submit">
                Pošaljite upit
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
