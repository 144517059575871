import React, { useState, useEffect } from "react";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./References.css";
import { Oval } from "react-loader-spinner";
import API_BASE_URL from "../../config";

function References() {
  const [projects, setProjects] = useState([]); // Stores API data
  const [loadingStates, setLoadingStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentProjectImages, setCurrentProjectImages] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  // ✅ Fetch References from Public API (No Auth)
  useEffect(() => {
    fetchReferences();
  }, []);

  const fetchReferences = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/references/public`);
      
      // Transform API data to match expected structure
      const formattedProjects = response.data.map((ref) => ({
        title: ref.title,
        category: ref.category,
        images: ref.images || [],
      }));

      setProjects(formattedProjects);
      setLoadingStates(new Array(formattedProjects.length).fill(true));

      // Preload images
      preloadImages(formattedProjects);
    } catch (error) {
      console.error("Error fetching references:", error);
    }
  };

  // ✅ Preload images for smoother UI
  const preloadImages = (projects) => {
    projects.forEach((project, index) => {
      const imagePromises = project.images.map((src) => {
        const img = new Image();
        img.src = src;
        return new Promise((resolve) => {
          img.onload = resolve;
          img.onerror = resolve;
        });
      });

      Promise.all(imagePromises).then(() => {
        setLoadingStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = false;
          return newStates;
        });
      });
    });
  };

  const openLightbox = (images, index) => {
    setCurrentProjectImages(images);
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // ✅ Sticky header effect
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const navbarHeight = 75;
      setIsSticky(scrollY >= navbarHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // ✅ Group projects by category
  const groupedProjects = projects.reduce((acc, project) => {
    if (!acc[project.category]) {
      acc[project.category] = [];
    }
    acc[project.category].push(project);
    return acc;
  }, {});

  return (
    <>
      <div className={`references-page--container ${isSticky ? "sticky-active" : ""}`}>
        <div className="references-pages--disclaimer">
          <p>
            Neke od ovih vizualizacija nisu u vlasništvu naše firme, već su radovi drugih izvođača. 
            Ove vizualizacije koriste se isključivo u svrhu prikaza naših referenci.
          </p>
        </div>
        <div className="references-pages--title">Pogledajete naše reference...</div>
      </div>

      <div className="references-gallery-container">
        {Object.keys(groupedProjects).map((category) => (
          <div key={category} className="references-category-group">
            <h2 className="references-category-title">{category}</h2>
            <div className="references-galleryWrapper">
              {groupedProjects[category].map((project, projectIndex) => (
                <div className="references-galleryItem" key={projectIndex}>
                  <div className="references-galleryItem-content">
                    {loadingStates[projects.indexOf(project)] && (
                      <div className="references-gallery-overlay">
                        <Oval height="50" width="50" color="#405783" ariaLabel="loading" visible={true} />
                      </div>
                    )}
                    <img
                      src={project.images[0] || "/placeholder.jpg"} // Use first image or placeholder
                      alt={project.title}
                      onClick={() => openLightbox(project.images, 0)}
                      className={`references-galleryImage ${loadingStates[projects.indexOf(project)] ? "loading" : ""}`}
                    />
                    {!loadingStates[projects.indexOf(project)] && (
                      <div className="references-imageTitle">{project.title}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={currentProjectImages[photoIndex]}
          nextSrc={currentProjectImages[(photoIndex + 1) % currentProjectImages.length]}
          prevSrc={currentProjectImages[(photoIndex + currentProjectImages.length - 1) % currentProjectImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + currentProjectImages.length - 1) % currentProjectImages.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % currentProjectImages.length)}
        />
      )}
    </>
  );
}

export default References;
